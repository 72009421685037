import { Offcanvas } from "react-bootstrap";
import "./ThankyouOffcanvas.css";
import ThankyouIcon from "../../assets/images/thankyou.png";
export default function ThankyouOffcanvas({
  showOffcanvas,
  setShowThankyouModal,
  email
}) {
  const onclose = () => {
    setShowThankyouModal(false);
  };
  return (
    <>
      <Offcanvas show={showOffcanvas} onHide={onclose} placement="end">
        <Offcanvas.Header className="pb-0" closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0 thankyou-modal">
          <div className="text-center mb-4 thankyou-img">
            <img src={ThankyouIcon} />
          </div>
          <h4 class="mb-2 text-center mb-3">
            Thank you for Registering on{" "}
            <span>
              <b>Grub</b>
            </span>{" "}
            <b>Digest</b>.
          </h4>
          <p>
            We have sent you a mail on <b>{email}</b> with your Login
            Credentials to manage your restaurant details, and a link to
            download your personalised QR code for your Digital Menu. Please
            check your email and verify your registration by logging in using
            the credentials sent to you.
          </p>
          <p className="my-4">
            You can setup your Restaurant Profile & add your Food & Beverages
            Menu and activate your Digital Menu.
          </p>
          <div className="text-center bottom-two-text mb-5">
            <h6>It’s not about the what you sell</h6>
            <h6 className="text-red">It’s about the stories you tell</h6>
          </div>
          <div className="text-center thankyou-btn" onClick={() => { onclose() }}>
            <a href="https://manage.grubdigest.com/" target="_blank" className="btn btn-danger">Go To Admin Panel</a>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
