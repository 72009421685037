import { Select, Skeleton } from "antd";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import { IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { PiBowlFoodFill } from "react-icons/pi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GetApiCall from "../../API/GETAPI";

const { Option } = Select;

function GrubDigestSearchBar() {
  let location = useLocation();
  let param = useParams();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [filter, SetFilter] = useState([]);
  const [cuisinesId, setCuisinesId] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [showNavbar, setShowNavbar] = useState(false);
  const [searchText, setSearchText] = useState("");
  const handleNavbar = () => setShowNavbar(false);

  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    if (location.state != null && typeof location.state === "number") {
      cuisinesId.push(location.state);
    }
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    GetApiCall.getRequest("getSearchData").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setSearchData(obj1.message);
            setLoading(false);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  const getCuisine = () => {
    GetApiCall.getRequest("getCuisines").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            SetFilter(obj1.message);
            setLoading(false);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const getRestaurantType = () => {
    GetApiCall.getRequest("getrestauranttype").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            SetFilter(obj1.message);
            setLoading(false);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  // const onSearch = RegExp(
  //   `.*${searchValue.toLowerCase().split("").join(".*")}.*`
  // );
  // const handleCusineChange = (event) => {
  //   const id = parseInt(event.target.value);

  //   if (event.target.checked) {
  //     if (type == "cuisine") {
  //       setCuisinesId((prevIds) => [...prevIds, id]);
  //     } else {
  //       setRestaurantTypeId((prevIds) => [...prevIds, id]);
  //     }
  //   } else {
  //     if (type == "cuisine") {
  //       setCuisinesId((prevIds) => prevIds.filter((prevId) => prevId !== id));
  //     } else {
  //       setRestaurantTypeId((prevIds) =>
  //         prevIds.filter((prevId) => prevId !== id)
  //       );
  //     }
  //   }
  // };
  const filteredData = searchData.filter((dat) => {
    return (
      dat.fld_name.toLowerCase().includes(searchText.toLowerCase()) ||
      dat.fld_address.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  return (
    <section className="services pt-lg-3 pb-70">
      <div className="container-fluid px-lg-4 cuisines">
        <div className="row justify-content-center mt-3">
          <div className="col-lg-12 col-xl-11 col-xxl-10 align-items-start search-section px-0">
            <div className="card shadow text-center p-3 search-card">
              <form>
                <div className="form-row">
                  <div className="col-lg-4 col-12">
                    <h4 className="extra-text mb-lg-0 mb-2 no-text-shadow pt-1">
                      Good <span className="no-text-shadow">Mood</span>, Find{" "}
                      <span className="no-text-shadow">Food</span>
                    </h4>
                  </div>
                  <div className="col-lg-8 col-12 search-input d-none d-md-block">
                    <input
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      placeholder="Look for restaurants, location, cuisines or a dish"
                    />
                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                  <div
                    className={
                      filteredData.length > 0 && searchText !== ""
                        ? "search-result-pc-show search-result-pc"
                        : "search-result-pc"
                    }>
                    {filteredData.length > 0 && searchText !== "" && (
                      <div>
                        {filteredData.map((data) => {
                          return (
                            <div
                              onClick={() => {
                                navigate(`${data.fld_route}`, {
                                  state: data.fld_client_id,
                                });
                              }}
                              className="d-flex search-result-card">
                              <img
                                src={data.fld_image}
                                alt="img"
                                className="dish-image"
                                loading="lazy"
                              />
                              <div>
                                <div
                                  className="review-profile-info fw-bold mb-1"
                                  style={{ marginLeft: "8px" }}>
                                  {data.fld_name}
                                </div>
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    display:
                                      data.fld_address == null
                                        ? "none"
                                        : "block",
                                  }}>
                                  {data.fld_address}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-8 col-12 search-input d-block d-md-none"
                    onClick={() => setShowNavbar(true)}>
                    <div className="search-input-text">
                      <span>
                        Look for restaurants, location, cuisines or a dish
                      </span>
                    </div>
                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div
              className="card shadow text-center ms-2 filter-card"
              onClick={() => {
                setShow(true);
                getCuisine();
                setType("cuisine");
              }}
            >
              <span className="search-icon rounded d-flex flex-column align-items-center">
                <PiBowlFoodFill className="search-icon-svg" />
                <p className="filter-label">Cuisine</p>
              </span>
            </div>
            <div
              className="card shadow text-center ms-lg-2 filter-card"
              onClick={() => {
                setShow(true);
                getRestaurantType();
                setType("restauranttype");
              }}
            >
              <span className="search-icon rounded text-center">
                <IoRestaurantOutline className="search-icon-svg" />
                <p className="filter-label">Restaurant</p>
              </span>
            </div>
            {param.value == undefined ? (
              ""
            ) : (
              <div
                className="card shadow text-center ms-2 filter-card"
                onClick={() => {
                  window.location = "/restaurants";
                }}
              >
                <span className="search-icon rounded text-center">
                  <MdOutlineCancel className="search-icon-svg" />
                  <p className="filter-label">Clear Filter</p>
                </span>
              </div>
            )}
          </div>
          {/* {param.value == undefined ? (
            ""
          ) : (
            <a
              href="/restaurants"
              className="card shadow text-center ms-2 filter-card clear-filter text-decoration-none clear-filter-desktop"
            >
              <span className="search-icon rounded text-center">
                <MdOutlineCancel className="search-icon-svg" />
                <p className="filter-label">Clear Filter</p>
              </span>
            </a>
          )} */}
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="review-canvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {type == "cuisine" ? "Explore Cuisines" : "Type of Restaurants"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="hero">
            {loading == true ? (
              <div className="row">
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
                <div className="col-3 mb-4 px-3 position-relative d-block">
                  <Skeleton.Image active={true} />
                </div>
              </div>
            ) : (
              <div className="row">
                {filter.map((data) => {
                  return (
                    <>
                      <div className="col-lg-3 col-4 px-3 position-relative d-block">
                        <a
                          href={`/restaurants/${type}/${data.fld_name}/${data.fld_id}`.replace(
                            / /g,
                            "-"
                          )}
                        >
                          <div className="item min-height-auto text-center cursor-pointer">
                            <img
                              src={data.fld_image}
                              className="services-icon"
                              alt=""
                            />
                            <h6 className="services-icon-name">
                              {data.fld_display_name}
                            </h6>
                            {/* <Form.Check
                                                        value={data.fld_id}
                                                        onChange={(e) => {
                                                            handleCusineChange(e)
                                                        }}
                                                        checked={type == "Cuisines" ? cuisinesId.includes(data.fld_id) : restaurantTypeId.includes(data.fld_id)}
                                                        type="checkbox"
                                                        className="facilities-selection"
                                                    /> */}
                          </div>
                        </a>
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={showNavbar}
        onHide={handleNavbar}
        placement="bottom"
        className="search-offcanvas">
        <Offcanvas.Header
          closeButton
          className=" border-0 justify-content-end"></Offcanvas.Header>
        <Offcanvas.Body className="pt-0 overflow-x-hidden">
          <div className="search-input sticky-top bg-white pb-2">
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Look for restaurants, location, cuisines or a dish"
            />
            <span className="search-icon">
              <BiSearchAlt className="search-icon-svg" />
            </span>
          </div>
          {searchData
            .filter((dat) => {
              if (
                dat.fld_name.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return dat;
              }
              if (
                dat.fld_address.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return dat;
              }
            })
            .map((data) => {
              return (
                <div
                  onClick={() => {
                    navigate(`${data.fld_route}`, {
                      state: data.fld_client_id,
                    });
                  }}
                  className="d-flex search-result-card">
                  <img
                    src={data.fld_image}
                    alt="img"
                    className="dish-image"
                    loading="lazy"
                  />
                  <div>
                    <div
                      className="review-profile-info fw-bold mb-1"
                      style={{ marginLeft: "8px" }}>
                      {data.fld_name}
                    </div>
                    <div
                      style={{
                        marginLeft: "8px",
                        display: data.fld_address == null ? "none" : "block",
                      }}>
                      {data.fld_address}
                    </div>
                  </div>
                </div>
              );
            })}
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
}

export default GrubDigestSearchBar;
