import { Select, Skeleton } from "antd";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Offcanvas,
  Row
} from "react-bootstrap";
import { BiLike } from "react-icons/bi";
import {
  FaDirections,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn, FaPhoneVolume,
  FaPinterestP,
  FaWhatsapp
} from "react-icons/fa";
import {
  IoStar,
  IoStarHalfOutline,
  IoStarOutline
} from "react-icons/io5";
import { TfiYoutube } from "react-icons/tfi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import GetApiCall from "../../API/GETAPI";
import POSTAPI from "../../API/POSTAPI";
import TenTwentyTwo from "../../assets/images/featured_restaurants/TenTwentyTwo.png";
import ChefInfo from "../../component/ChefInfo/ChecfInfo";
import ImagesPreview from "../../component/ImageUpload/ImagesPreview";
import GrubDigestSearchBar from "../../component/SearchBar/GrubDigestSearchBar";
import "./RestaurantInfo.css";

const { Option } = Select;

function RestaurantInfo() {
  let params = useParams();
  let firstObjects;
  let navigate = useNavigate();
  const firstObjectsMap = new Map();
  const [topImages, setTopImages] = useState([]);
  const [resImages, setResImages] = useState([]);
  const [menuSummary, setMenuSummary] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [menuTypeName, setMenuTypeName] = useState([]);
  const [menuModal, setMenuModal] = useState(false);
  const [menuTypes, setMenuTypes] = useState([]);
  const [featuredRestaurant, setFeaturedRestaurant] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const handleCloseMenuModal = () => {
    setMenuModal(false);
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    GetApiCall.getRequest("getSearchData").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setSearchData(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
    POSTAPI.postRequest(
      {
        restaurantId: params.id,
      },
      "getRestaurantMasterFilter"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            console.log(obj1.message)

            setFeaturedRestaurant(obj1.message.message);
            setTopImages(obj1.message.topimages);
            setResImages(obj1.message.allimages);
            setMenuSummary(obj1.message.menusummary);
            obj1.message.menusummary.forEach((obj) => {
              if (!firstObjectsMap.has(obj.fld_menu)) {
                firstObjectsMap.set(obj.fld_menu, obj);
              }
            });
            console.log(Array.from(firstObjectsMap.values()))
            setMenuTypes(Array.from(firstObjectsMap.values()));
            setLoading(false);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, [params.id]);
  // const firstObjects = Object.values(filteredData);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const [showReview, setShowReview] = useState(false);
  const handleClose = () => setShowReview(false);
  const handleShow = () => setShowReview((s) => !s);
  // function toDateWithOutTimeZone(date) {
  //   let tempTime = date.split(":"); let dt = new Date(); dt.setHours(tempTime[0]); dt.setMinutes(tempTime[1]); dt.setSeconds(tempTime[2]); return (dt);
  // }
  return (
    <section className="services pt-lg-3 pb-70" data-scroll-index="3">
      {loading == true ? (
        <div className="container-fluid px-lg-4 cuisines">
          <Row>
            <Col lg={12} xs={12} className="my-5">
              <Skeleton.Input active={true} className="w-100" />
            </Col>
          </Row>
          <Row>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height d-none d-lg-flex"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height d-none d-lg-flex"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Skeleton active />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Skeleton.Button active={true} block={false} className="mb-3" />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height d-none d-lg-flex"
              />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height d-none d-lg-flex"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Skeleton.Button active={true} block={false} className="mb-3" />
            </Col>
            <Col lg={3} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
            <Col lg={3} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Skeleton.Button active={true} block={false} className="mb-3" />
            </Col>
            <Col lg={12}>
              <Skeleton.Input active={true} block={false} className="mb-3" />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Skeleton.Button active={true} block={false} className="mb-3" />
            </Col>
            <Col lg={2} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Skeleton.Button active={true} block={false} className="mb-3" />
            </Col>
            <Col lg={6} className="mb-3">
              <Skeleton active />
            </Col>
            <Col lg={6} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height"
              />
            </Col>
          </Row>
        </div>
      ) : (
        <div className="container-fluid px-lg-4 cuisines">
          <div className="row">
            {/* <div className="card shadow text-center p-3 w-100 mb-4">

              <form>
                <div className="form-row">
                  <div className="col-lg-4 col-12">
                    <h4 className="extra-text mb-lg-0 mb-2 no-text-shadow pt-1">
                      Good <span className="no-text-shadow">Mood</span>, Find{" "}
                      <span className="no-text-shadow">Food</span>
                    </h4>
                  </div>
                  <div className="col-lg-8 col-12 search-input">
                    <Select
                      showSearch
                      style={{
                        width: "100%",
                      }}
                      placeholder="Look for restaurants,location, cuisines or a dish"
                      optionFilterProp="children"
                      className="form-control pl-5"
                      filterOption={(input, option) => (option?.label.toLowerCase()).includes(input)}
                    // filterSort={(optionA, optionB) =>
                    //   (optionA?.label).toLowerCase().localeCompare((optionB?.label).toLowerCase())
                    // }
                    >
                      {searchData.map((data) => {
                        return (
                          <Option value={data.fld_id} label={data.fld_name}>
                            <div onClick={() => { navigate(`${data.fld_route}`, { state: data.fld_client_id }) }} className="d-flex">
                              <img src={data.fld_image} style={{ width: "70px" }} alt="img review-profile-pic" />
                              <div>
                                <div className="review-profile-info fw-bold mb-1" style={{ marginLeft: '8px' }}>{data.fld_name}</div>
                                <div style={{ marginLeft: '8px', display: data.fld_address == null ? "none" : "block" }}>{data.fld_address}</div>
                              </div>
                            </div>
                          </Option>
                        )
                      })}
                    </Select>


                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                </div>
              </form>
            </div> */}
            {/* <div className="row justify-content-center align-items-center mb-5">
              <div className="col-lg-9 search-section">
                <div className="card shadow text-center p-3 search-card">
                  <form>
                    <div className="form-row">
                      <div className="col-lg-4 col-12">
                        <h4 className="extra-text mb-lg-0 mb-2 no-text-shadow pt-1">
                          Good <span className="no-text-shadow">Mood</span>, Find{" "}
                          <span className="no-text-shadow">Food</span>
                        </h4>
                      </div>
                      <div className="col-lg-8 col-12 search-input">
                        <Select
                          showSearch
                          style={{
                            width: "100%",
                          }}
                          placeholder="Look for restaurants,location, cuisines or a dish"
                          optionFilterProp="children"
                          className="form-control pl-5"
                          filterOption={(input, option) => (option?.label.toLowerCase()).includes(input)}
                        // filterSort={(optionA, optionB) =>
                        //   (optionA?.label).toLowerCase().localeCompare((optionB?.label).toLowerCase())
                        // }
                        >
                          {searchData.map((data) => {
                            return (
                              <Option value={data.fld_id} label={data.fld_name}>
                                <div onClick={() => { navigate(`${data.fld_route}`, { state: data.fld_client_id }) }} className="d-flex">
                                  <img src={data.fld_image} style={{ width: "70px" }} alt="img review-profile-pic" />
                                  <div>
                                    <div className="review-profile-info fw-bold mb-1" style={{ marginLeft: '8px' }}>{data.fld_name}</div>
                                    <div style={{ marginLeft: '8px', display: data.fld_address == null ? "none" : "block" }}>{data.fld_address}</div>
                                  </div>
                                </div>
                              </Option>
                            )
                          })}
                        </Select>
                        <span className="search-icon">
                          <BiSearchAlt className="search-icon-svg" />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card shadow text-center ms-2 filter-card">
                  <span className="search-icon rounded d-flex flex-column align-items-center" onClick={() => {
                    // setShow(true)
                    // getCuisine()
                    // setType("Cusines")
                  }}>
                    <PiBowlFoodFill className="search-icon-svg" />
                    <p className="filter-label">Cuisine</p>
                  </span>
                </div>
                <div className="card shadow text-center ms-2 filter-card">
                  <span className="search-icon rounded text-center" onClick={() => {
                    // setShow(true)
                    // getRestaurantType()
                    // setType("Restaurant Type")
                  }}>
                    <IoRestaurantOutline className="search-icon-svg" />
                    <p className="filter-label">Restaurant</p>
                  </span>
                </div>
              </div>
            </div> */}
            <GrubDigestSearchBar />
          </div>
          <div className="row pe-0">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="mx-2 pb-0"
            >
              {topImages.map((img) => {
                return (
                  <div>
                    <img
                      src={img.fld_image_url}
                      className="img-fluid w-100 rounded"
                      alt=""
                    />
                  </div>
                );
              })}
            </Carousel>
            {/* {topImages.map((img) => {
              return (
                // <Col lg={3} xs={6} className="mb-3 pr-0">
                //   <img
                //     src={img.fld_image_url}
                //     className="img-fluid w-100 rounded"
                //     alt=""
                //   />
                // </Col>
              );
            })} */}
          </div>
          {featuredRestaurant.map((data) => (
            <>
              {data.fld_is_chef === "True" ? (
                <ChefInfo />
              ) : (
                <div className="">
                  <div className="col-lg-12"></div>
                  <div className="col-lg-12 position-relative">
                    <div className="row">
                      <div className="col-lg-6 d-flex justify-content-center align-items-center pl-lg-0 px-0 px-lg-2">
                        <img
                          src={data.fld_image}
                          className="img-fluid w-100 rounded"
                        />
                      </div>
                      <div className="col-lg-6 p-0 px-lg-2">
                        <div className="py-3 py-lg-0">
                          <h3 className="mb-2 fw-bold">
                            {data.fld_restaurantname}
                          </h3>
                          <p className="mb-2 fs-5">{data.cusines_list}</p>
                          {/* <div className="d-lg-flex">
                              <a
                                href=""
                                className="text-decoration-none text-dark"
                                title="Like"
                              >
                                <div className="like-block">
                                  <span className="mr-2">{data.fld_likes}</span>
                                  <BiLike className="rating-star" />
                                </div>
                              </a>
                              <a
                                href=""
                                className="text-decoration-none text-dark"
                                title="Rating"
                              >
                                <div className="rating-block">
                                  <span className="mr-2">{data.fld_rating}</span>
                                  <Rating
                                    placeholderRating={data.fld_rating}
                                    emptySymbol={
                                      <img src={stargray} className="icon" />
                                    }
                                    placeholderSymbol={
                                      <img src={starred} className="icon" />
                                    }
                                    fullSymbol={
                                      <img src={staryellow} className="icon" />
                                    }
                                  />
                                </div>
                              </a>
                              <a
                                className="text-decoration-none text-dark"
                                onClick={handleShow}
                                title="Review"
                              >
                                <div className="reviews-block">
                                  <span className="mr-2">
                                    {data.fld_reviews_count}
                                  </span>
                                  <BiCommentDetail className="rating-star" />
                                </div>
                              </a>
                            </div> */}
                          <Offcanvas
                            show={showReview}
                            onHide={handleClose}
                            backdrop="static"
                            placement="end"
                            className="review-canvas"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                Customer Reviews
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div>
                                <Card className="mb-3 shadow-sm">
                                  <Card.Body>
                                    <div className="d-flex">
                                      <img
                                        src={TenTwentyTwo}
                                        className="review-profile-pic"
                                      />
                                      <div className="review-profile-info">
                                        <p className="fw-bold mb-1">
                                          User Name
                                        </p>
                                        <div className="d-lg-flex">
                                          <a
                                            href=""
                                            className="text-decoration-none text-dark"
                                            title="Like"
                                          >
                                            <div className="like-block">
                                              <span className="mr-2">
                                                {data.like}
                                              </span>
                                              <BiLike className="rating-star" />
                                            </div>
                                          </a>
                                          <a
                                            href=""
                                            className="text-decoration-none text-dark"
                                            title="Rating"
                                          >
                                            <div className="rating-block">
                                              <span className="mr-2">
                                                {data.rating}
                                              </span>
                                              <IoStar className="rating-star" />
                                              <IoStar className="rating-star" />
                                              <IoStar className="rating-star" />
                                              <IoStarHalfOutline className="rating-star" />
                                              <IoStarOutline className="rating-star" />
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        So, as far as a nice setting, this
                                        restaurant has it. But execution on
                                        the food? Not even close to being
                                        worth the money.
                                      </p>
                                    </div>
                                  </Card.Body>
                                </Card>
                                <Card className="mb-3 shadow-sm">
                                  <Card.Body>
                                    <div className="d-flex">
                                      <img
                                        src={TenTwentyTwo}
                                        className="review-profile-pic"
                                      />
                                      <div className="review-profile-info">
                                        <p className="fw-bold mb-1">
                                          User Name
                                        </p>
                                        <div className="d-lg-flex">
                                          <a
                                            href=""
                                            className="text-decoration-none text-dark"
                                            title="Like"
                                          >
                                            <div className="like-block">
                                              <span className="mr-2">
                                                {data.like}
                                              </span>
                                              <BiLike className="rating-star" />
                                            </div>
                                          </a>
                                          <a
                                            href=""
                                            className="text-decoration-none text-dark"
                                            title="Rating"
                                          >
                                            <div className="rating-block">
                                              <span className="mr-2">
                                                {data.rating}
                                              </span>
                                              <IoStar className="rating-star" />
                                              <IoStar className="rating-star" />
                                              <IoStar className="rating-star" />
                                              <IoStarHalfOutline className="rating-star" />
                                              <IoStarOutline className="rating-star" />
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        So, as far as a nice setting, this
                                        restaurant has it. But execution on
                                        the food? Not even close to being
                                        worth the money.
                                      </p>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            </Offcanvas.Body>
                            <div className="sticky-bottom bg-white p-3">
                              <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Write your review here"
                              >
                                <Form.Control
                                  as="textarea"
                                  placeholder="Write your review here"
                                  style={{ height: "100px" }}
                                />
                              </FloatingLabel>
                              <Button
                                type="submit"
                                className="mt-2 mb-3 float-right"
                                variant="danger"
                              >
                                Submit
                              </Button>
                            </div>
                          </Offcanvas>
                          <p className="mb-2 fs-5">{data.fld_address}</p>
                          {data.fld_working_hours?.split("#").map((time) => {
                            return (
                              <>
                                {
                                  time.split(",")[0] ==
                                    data.fld_today.toLowerCase()
                                    ?
                                    <p className="mb-2" >
                                      {/* {data.fld_time_now == betwee} */}
                                      {/* {toDateWithOutTimeZone(time.split(",")[1])} */}
                                      <span className="grub-green">Open Now -</span>{" "}
                                      {time.split(",")[0] ==
                                        data.fld_today.toLowerCase()
                                        ? time.split(",")[1] +
                                        "-" +
                                        time.split(",")[2]
                                        : ""}
                                    </p>
                                    : ""}
                              </>
                            );
                          })}

                          <div className="d-lg-flex align-items-center social-block mt-3">
                            <div className="d-flex mb-lg-0 mb-3">
                              <a
                                href={data.fld_facebook}
                                target="_blank"
                                className="bg-light social-action"
                                style={{
                                  display:
                                    data.fld_facebook == null ||
                                      data.fld_facebook == ""
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <FaFacebookF className="social-share" />
                              </a>
                              <a
                                href={data.fld_instagram}
                                target="_blank"
                                className="bg-light social-action"
                                style={{
                                  display:
                                    data.fld_instagram == "" ||
                                      data.fld_instagram == null
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <FaInstagram className="social-share" />
                              </a>
                              <a
                                href={data.fld_youtube}
                                target="_blank"
                                className="bg-light social-action"
                                style={{
                                  display:
                                    data.fld_youtube == null ||
                                      data.fld_youtube == ""
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <TfiYoutube className="social-share" />
                              </a>
                              <a
                                href={data.fld_pintrust}
                                target="_blank"
                                className="bg-light social-action"
                                style={{
                                  display:
                                    data.fld_pintrust == "" ||
                                      data.fld_pintrust == null
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <FaPinterestP className="social-share" />
                              </a>
                              <a
                                href={data.fld_linkedin}
                                target="_blank"
                                className="bg-light social-action"
                                style={{
                                  display:
                                    data.fld_linkedin == "" ||
                                      data.fld_linkedin == null
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <FaLinkedinIn className="social-share" />
                              </a>
                              {/* </div>
                              <div className="action-button-wrap mb-lg-0 mb-3"> */}
                              { }
                              <a
                                href=""
                                style={{
                                  display:
                                    data.fld_whatsappnumber == ""
                                      ? "none"
                                      : "flex",
                                }}
                                className="bg-light text-decoration-none text-dark align-items-center px-lg-3 py-lg-0 mb-lg-0 mb-2 flex-grow-lg-1"
                                onClick={() => {
                                  window.open(
                                    `https://api.whatsapp.com/send?phone=91${data.fld_whatsappnumber}`
                                  );
                                }}
                              >
                                <FaWhatsapp className="social-share action-btn" />
                                <span className="d-none d-md-inline">
                                  WhatsApp
                                </span>
                              </a>
                              <a
                                style={{
                                  display:
                                    data.fld_phonenumber == ""
                                      ? "none"
                                      : "flex",
                                }}
                                href={`tel:+${data.fld_phonenumber}`}
                                className="bg-light text-decoration-none text-dark align-items-center px-lg-3 py-lg-0 mb-lg-0 mb-2 flex-grow-lg-1"
                              >
                                <FaPhoneVolume className="social-share action-btn rotate-icon" />
                                <span className="d-none d-md-inline">
                                  Call Restaurant
                                </span>
                              </a>
                              <a
                                href={data.fld_location_link}
                                style={{
                                  display:
                                    data.fld_location_link == ""
                                      ? "none"
                                      : "flex",
                                }}
                                target="_blank"
                                className="bg-light text-decoration-none text-dark align-items-center px-lg-3 py-lg-0 mb-lg-0 mb-0 flex-grow-lg-1"
                              >
                                <FaDirections className="social-share action-btn" />
                                <span className="d-none d-md-inline">
                                  Directions
                                </span>
                              </a>
                              {/* <a href="" className="btn btn-danger px-3">
                            Order Online
                          </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row pe-0 featured-events">
                <div className="col-12">
                  <p className="tab-section-title">Photo Gallery</p>
                </div>
                <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  keyBoardControl={true}
                  containerClass="carousel-container"
                  dotListClass="custom-dot-list-style"
                  itemClass="mx-2 mb-4"
                >
                  {resImages.map((img) => {
                    return (
                      <div>
                        <img
                          src={img.fld_image_url}
                          className="img-fluid w-100 rounded"
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Carousel>
              </div>
              <div className="col-lg-12">
                <div className="mb-4">
                  <p className="tab-section-title">Menu</p>
                  <div className="row">
                    {menuTypes.map((data) => {
                      return (
                        <div className="col-lg-3 col-md-6">
                          <div
                            onClick={() => {
                              setMenuModal(true);
                              setMenuTypeName(data.fld_menu);
                            }}
                            className="d-block overflow-hidden upload-image rounded cursor-pointer"
                          >
                            <img
                              src={data.fld_image_url}
                              className="img-fluid w-100 mb-2 rounded menu-image-cover-fit"
                            />
                          </div>
                          <p className="font-weight-bold mb-0">
                            {data.fld_menu}
                          </p>
                          <p className="">{data.menu_total} Pages</p>
                        </div>
                      );
                    })}
                    {data.fld_show_digital_menu === "True" ?
                      <div className="col-lg-3 col-md-6">
                        <div
                          // onClick={() => {
                          //   setMenuModal(true);
                          //   setMenuTypeName(data.fld_menu);
                          // }}
                          className="d-flex justify-content-center align-items-center overflow-hidden upload-image rounded cursor-pointer"
                        >
                          <a
                            href={`/digitalmenu/${params.name}/${params.id}`}
                            className="btn btn-danger"
                          >
                            View Digital Menu
                          </a>
                        </div>
                      </div>
                      : ""}
                  </div>
                </div>
                <div className="mb-4">
                  <p className="tab-section-title">Cuisines</p>
                  <div className="col-12">
                    <div className="row">
                      {featuredRestaurant[0]?.cusines_list
                        ?.split(",")
                        .map((data) => (
                          <Link className="btn btn-outline-danger cuisine-pills">
                            {data}
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
                <div
                  className="mb-4"
                  dangerouslySetInnerHTML={{ __html: data.fld_other_info }}
                ></div>
                <div className="mb-4">
                  <p className="tab-section-title">Facilities</p>
                  <div className="hero">
                    <div className="row">
                      {data.facilities_list
                        ?.split(",")
                        .map((datafacilities) => (
                          <div className="restaurant-type-wrap restaurant-type-wrap-home restaurant-type-wrap-detail px-3">
                            <Link
                              to=""
                              className="d-block position-relative text-decoration-none text-dark"
                            >
                              <div className="item min-height-auto text-center pt-3">
                                <img
                                  src={datafacilities?.split("#")[1]}
                                  className="services-icon"
                                  alt=""
                                />
                                <h6 className="mb-0">
                                  {datafacilities?.split("#")[0]}
                                </h6>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="quick-contact">
                  <div className="row">
                    <div className="col-lg-6">
                      <p className="tab-section-title">Contact Information</p>
                      <p className="contact-restaurant-name mb-2">
                        {data.fld_restaurantname}
                      </p>
                      <div className="contact-address contact-detail">
                        <div>Address</div>
                        <div>
                          <p
                            className="mb-0 font-2x address-one"
                            style={{
                              display:
                                data.fld_address_1 == null ? "none" : "block",
                            }}
                          >
                            {data.fld_address_1}
                          </p>
                          <p
                            className="mb-0 font-2x address-two"
                            style={{
                              display:
                                data.fld_address_2 == null ? "none" : "block",
                            }}
                          >
                            {data.fld_address_2}
                          </p>
                          <p
                            className="mb-0 font-2x address-three"
                            style={{
                              display:
                                data.fld_address_3 == null ? "none" : "block",
                            }}
                          >
                            {data.state_name}, {data.city_name}
                          </p>
                          <p
                            className="mb-0 font-2x address-four"
                            style={{
                              display:
                                data.fld_address_4 == null ? "none" : "block",
                            }}
                          >
                            {data.country_name}
                          </p>
                        </div>
                      </div>
                      <div className="contact-detail">
                        <div
                          className=""
                          style={{
                            display:
                              data.fld_mobilenumber == "" || data.fld_mobilenumber === null ? "none" : "block",
                          }}
                        >
                          Mobile
                        </div>
                        <div className="">
                          <a
                            href={`tel:+91${data.fld_phonenumber}`}
                            className="text-danger mb-2 text-decoration-none"
                            style={{
                              display:
                                data.fld_phonenumber == "" || data.fld_phonenumber == null ? "none" : "block",
                            }}
                          >
                            +91 {data.fld_phonenumber}
                          </a>
                          <a
                            href={`tel:+91${data.fld_mobilenumber}`}
                            target="_blank"
                            className="text-danger mb-2 text-decoration-none"
                            style={{
                              display:
                                data.fld_mobilenumber == ""
                                  ? "none"
                                  : "block",
                            }}
                          >
                            +91 {data.fld_mobilenumber}
                          </a>
                        </div>
                      </div>
                      <div className="contact-detail">
                        <div
                          className=""
                          style={{
                            display: data.fld_email == "" ? "none" : "block",
                          }}
                        >
                          Email
                        </div>
                        <div
                          className=""
                          style={{
                            display: data.fld_email == "" ? "none" : "block",
                          }}
                        >
                          <a
                            href={`mailto:${data.fld_email}`}
                            target="_blank"
                            className="d-block text-danger mb-2 text-decoration-none"
                          >
                            {data.fld_email}
                          </a>
                        </div>
                      </div>
                      <div
                        className="contact-detail"
                        style={{
                          display: data.fld_website == "" || data.fld_website === null ? "none" : "flex",
                        }}
                      >
                        <div className="">Website</div>
                        <div className="d-flex">
                          <a
                            href={data.fld_website}
                            // href={`https://${data.fld_website}`}
                            target="_blank"
                            className="d-block text-danger mb-2 text-decoration-none"
                          >
                            {data.fld_website}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <p className="tab-section-title">Location</p>
                      <iframe
                        src={data.fld_geo_location}
                        width={"100%"}
                        height={"300px"}
                        style={{ border: "0" }}
                      ></iframe>
                      {/* <a
                        href={data.fld_location_link}
                        className="btn btn-outline-danger w-auto"
                      >
                        <div className="d-flex align-items-center">
                          <MdDirections className="text-danger font-special" />{" "}
                          <span className="ml-2">Direction</span>
                        </div>
                      </a> */}
                    </div>
                  </div>
                </div>
                {/* <span className="font-weight-bold">
                  ₹ {data.minAmount} for two
                </span> */}
              </div>
            </>
          ))}
        </div>
        // </div>
      )
      }
      {/* <ImagesPreview images="" menuTypeName={menuTypeName} /> */}
      <Modal
        show={menuModal}
        onHide={handleCloseMenuModal}
        size="lg  "
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="mb-3 fs-5 text-danger" closeButton>
          {menuTypeName}
        </Modal.Header>

        <Modal.Body>
          <div>
            <ImagesPreview images="" menuTypeName={menuTypeName} />
          </div>
        </Modal.Body>
      </Modal>
    </section >
  );
}

export default RestaurantInfo;
