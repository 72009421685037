import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLink,
  FaPhoneSquare,
  FaPhoneSquareAlt,
  FaShareAltSquare,
  FaWhatsapp,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import PenguinTop from "../../assets/images/gototop.png";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsFillChatSquareHeartFill } from "react-icons/bs";

function Footer() {
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [scroll, setScroll] = useState(false);
  const [shareBtn, setShareBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);
  return (
    <>
      <footer>
        <div className="footer">
          <Container className="px-lg-2">
            <Row className="justify-content-lg-end justify-content-center">
              <Col lg={6} className="">
                <p className="design text-lg-end text-center">
                  All trademarks are properties of their respective owners.<br className="d-lg-none d-block" /> © Grub Digest. All rights reserved.
                </p>
                <p className="design text-lg-end text-center">
                  A Product of{" "}
                  <a
                    href="http://globaltrendz.com/"
                    target="_blank"
                    className="text-white text-decoration-none"
                  >
                    Global Trendz
                  </a>
                </p>
              </Col>

              {/* <Col
                lg={6}
                className="d-flex justify-content-lg-end justify-content-center align-items-center gap-3 order-lg-2 order-2"
              >
                <Link
                  to="/terms-and-conditions"
                  className="text-white text-decoration-none small mt-1"
                >
                  Terms And Conditions
                </Link>
                {" | "}
                <Link
                  to="/disclaimer"
                  className="text-white text-decoration-none small mt-1"
                >
                  Disclaimer
                </Link>
                {" | "}
                <Link
                  to="/privacy-policy"
                  className="text-white text-decoration-none small mt-1"
                >
                  Privacy Policy
                </Link>
              </Col> */}
            </Row>
          </Container>
        </div>
      </footer>
      <div className="d-none d-lg-flex">
        <a className="float-icon instagram" href="https://www.instagram.com/grubdigest.official/" target="_blank">
          <FaInstagramSquare className="social-icon instagram" />
        </a>
        <a className="float-icon facebook" href="https://www.facebook.com/grubdigest.official/" target="_blank">
          <FaFacebookSquare className="social-icon facebook" />
        </a>
        <a className="float-icon whatsapp" href={`https://api.whatsapp.com/send?phone=${7827795895}`} target="_blank">
          <FaWhatsappSquare className="social-icon whatsapp" />
        </a>
        <a className="float-icon youtube" href="https://www.youtube.com/channel/UCCxCsi2qKJx2gb9n1sGgH6g" target="_blank">
          <FaYoutubeSquare className="social-icon youtube" />
        </a>
        <a className="float-icon Phone" href={`tel:${7827795895}`} target="_blank">
          <FaPhoneSquareAlt className="social-icon Phone" />
        </a>
      </div>
      <div className="product-share-btns d-lg-none">
        <ul
          className={
            shareBtn == true
              ? "activ-share-btn"
              : ""
          }
        >
          <span
            onClick={() =>
              setShareBtn(!shareBtn)
            }
            className="p-share-btn">
            <BsFillChatSquareHeartFill />
          </span>
          <li>
            <a className=" instagram" href="https://www.instagram.com/grubdigest.official/" target="_blank">
              <FaInstagramSquare className="social-icon instagram" />
            </a>
          </li>
          <li>
            <a className=" facebook" href="https://www.facebook.com/grubdigest.official/" target="_blank">
              <FaFacebookSquare className="social-icon facebook" />
            </a>
          </li>
          <li>
            <a className=" youtube" href="https://www.youtube.com/channel/UCCxCsi2qKJx2gb9n1sGgH6g" target="_blank">
              <FaYoutubeSquare className="social-icon youtube" />
            </a>
          </li>
          <li>
            <a className=" whatsapp" href={`https://api.whatsapp.com/send?phone=${7827795895}`} target="_blank">
              <FaWhatsappSquare className="social-icon whatsapp" />
            </a>
          </li>
          <li>
            <a className="Phone" href={`tel:${7827795895}`} target="_blank">
              <FaPhoneSquareAlt className="social-icon Phone" />
            </a>
          </li>
        </ul>
      </div>
      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className={
          scroll
            ? "scroll-top show animate__animated animate__bounce"
            : "scroll-top"
        }
      >
        <img src={PenguinTop} alt="" />
      </button>
    </>
  );
}

export default Footer;
