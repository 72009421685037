import { Skeleton } from 'antd';
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useParams } from "react-router-dom";
import PostApiCall from "../../API/POSTAPI";
import "./ImageUpload.css";

const ImagesPreview = ({ images, menuTypeName }) => {
    let params = useParams();
    let restaurantId = params.id;
    const [selectImage, setSelectImage] = useState("")
    const [photos, setPhotos] = useState([]);
    const [imageId, setimageId] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setPhotos(images)
        if (menuTypeName == "gallery images") {
            getRestaurantImages();
        } else {
            getRestaurantMenuImages()
        }
    }, [])
    const getRestaurantImages = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_restaurant_id = ${restaurantId} and fld_type = '${menuTypeName}'`,
                columns: "*"
            }
            ,
            "getRestaurantImages").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1.message.length != 0) {
                            setPhotos(obj1.message)
                            setSelectImage(obj1.message[0].fld_image_url)
                            setLoading(false);
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    const getRestaurantMenuImages = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_restaurant_id = ${restaurantId} and fld_menu = '${menuTypeName}'`,
                columns: "*"
            }
            ,
            "getRestaurantMenuImages").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1.message.length != 0) {
                            setPhotos(obj1.message)
                            setSelectImage(obj1.message[0].fld_image_url)
                            setLoading(false);
                            let arr = [];
                            let obj = {};
                            obj1.message?.map((item) => {
                                obj = {
                                    original: item.fld_image_url,
                                    thumbnail: item.fld_image_url
                                }
                                arr.push(obj);
                            })
                            setPhotos(arr);
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    return (
        <>
            {loading == true ?
                <Row>
                    <Col lg={7}>
                        <Skeleton.Image active={true} className="large-image-skeleton" />
                    </Col>
                    <Col lg={12}>
                        <Skeleton.Image active={true} className="mr-2" />
                        <Skeleton.Image active={true} />
                    </Col>
                </Row>
                :
                <Row>
                    <ImageGallery items={photos} showPlayButton={false} />
                </Row>
            }
        </>
    );
};
export default ImagesPreview;
