import React from "react";
// import { BiLike } from "react-icons/bi";
// import Rating from "react-rating";
// import { Link } from "react-router-dom";
// import stargray from "../../assets/images/rating/star-grey.png";
// import starred from "../../assets/images/rating/star-red.png";
// import staryellow from "../../assets/images/rating/star-yellow.png";

function RestaurantCard(props) {
  const chars = {
    "&": "",
    "%": "",
    ",": "",
    "/ /": "-",
  };

  return (
    <>
      <div className="col-lg-3 col-md-6 mb-4">
        <a
          href={`/restaurant-info/${props.featuredRestaurant.fld_restaurantname
            .replace("%", "")
            .replace("& ", "")
            .replace(",", "")
            .replace("$", "")
            .replace("(", "")
            .replace(")", "")
            .replace(/ /g, "-")}-${props.featuredRestaurant.fld_address
              .replace("%", "")
              .replace("& ", "")
              .replace("(", "")
              .replace(")", "")
              .replace(",", "")
              .replace("$", "")
              .replace("/", "-")
              .replace(/ /g, "-")}/${props.featuredRestaurant.fld_client_id}`}
          className="text-decoration-none text-dark w-100 h-100"
        >
          <div className="item h-100 mb-0 p-0 restaurant-info-card d-flex flex-column justify-content-between">
            <div>
              <div className="position-relative">
                <img
                  src={props.featuredRestaurant.main_image}
                  className="services-icon"
                  alt=""
                // loading="lazy"
                />
                {/* <div className="d-lg-flex justify-content-between align-items-center like-and-rating"> */}
                {/* <a href="" className="text-decoration-none text-dark">
                      <div className="like-block">
                        <span className="mr-2">{props.featuredRestaurant.fld_rating}</span>
                        <BiLike className="rating-star" />
                      </div>
                    </a> */}
                {/* <a href="" className="text-decoration-none text-dark">
                      <div className="rating-block">
                        <Rating
                          placeholderRating={props.featuredRestaurant.fld_rating}
                          emptySymbol={<img src={stargray} className="icon" />}
                          placeholderSymbol={
                            <img src={starred} className="icon" />
                          }
                          fullSymbol={<img src={staryellow} className="icon" />}
                        />
                      </div>
                    </a> */}
                {/* </div> */}
              </div>
              <div className="p-2 d-flex flex-column justify-content-between">
                <div>
                  <h5 className="mb-0 fw-bold">
                    {props.featuredRestaurant.fld_restaurantname}
                  </h5>
                  <p className="card-cuisine-list">
                    {props.featuredRestaurant.cusines_list}
                  </p>
                  {/* <p className="mb-0">{props.featuredRestaurant.fld_address}</p> */}
                </div>

              </div>
            </div>
            <div
              className="p-2 pt-0"
              style={{
                display:
                  props.featuredRestaurant.fld_min_amount == null
                    ? "none"
                    : "block",
              }}
            >
              <hr className="mb-2 mt-0" />
              <p className="mb-0 fw-bold">
                ₹ {props.featuredRestaurant.fld_min_amount} for two
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}

export default RestaurantCard;
