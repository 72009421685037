import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/helper.css";
import "./assets/css/style.css";
import Footer from "./component/Footer/Footer";
import Navbar from "./component/Navbar/Navbar";
import Chefs from "./pages/Chef/Chef";
import ChefInfo from "./pages/Chef/ChefInfo";
import ContactUs from "./pages/ContactUs/ContactUs";
import Events from "./pages/Events/Events";
import Disclaimer from "./pages/Home/Disclaimer";
import HomeGlobal from "./pages/Home/HomeGlobal";
import HomeRestaurant from "./pages/Home/HomeRestaurant";
import PrivacyPolicy from "./pages/Home/PrivacyPolicy";
import TermsAndConditions from "./pages/Home/TermsAndConditions";
import Login from "./pages/Login/Login";
import OrderFormRestaurant from "./pages/OrderFormRestaurant/OrderFormRestaurant";
// import Productdetails from "./pages/Product/Product-details";
import AddRestaurant from "./pages/Restaurant/AddRestaurant";
import DigitalMenu from "./pages/Restaurant/DigitalMenu";
import Restaurant from "./pages/Restaurant/Restaurant";
import RestaurantInfo from "./pages/Restaurant/RestaurantInfo";

// import Events from "./pages/Events/Events";
// import Productdetails from "./pages/Product/Product-details";
//import ChooseCityModal from "./component/ChooseCityModal/ChooseCityModal";
import DigitalMenuQr from "./pages/Restaurant/DigitalMenuQr";
import PageNotFound from "./pages/NotFound/PageNotFound";
import OnBoardingLandingPage from "./pages/OnBoardingLandingPage/OnBoardingLandingPage";
function App() {
  return (
    <BrowserRouter>
      {window.location.pathname.split("/")[1] == "digitalmenu" ||
        window.location.pathname.split("/")[1] == "qr" ? (
        <></>
      ) : (
        <Navbar />
      )}
      {/* <ChooseCityModal /> */}
      <Routes>
        <Route exact path="/" element={<HomeRestaurant />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/restaurants" element={<Restaurant />} />
        <Route
          exact
          path="/restaurants/:type/:value/:id"
          element={<Restaurant />}
        />
        <Route
          exact
          path="/restaurant-info/:name/:id"
          element={<RestaurantInfo />}
        />
        <Route exact path="/add-restaurant/:id" element={<AddRestaurant />} />
        <Route exact path="/Chefs" element={<Chefs />} />
        <Route exact path="/events" element={<Events />} />
        <Route exact path="/chef-info" element={<ChefInfo />} />
        <Route
          exact
          path="/terms-and-conditions"
          element={<TermsAndConditions />}
        />
        <Route exact path="/disclaimer" element={<Disclaimer />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/about-grub-digest" element={<HomeGlobal />} />
        {/* <Route exact path="/productdetails" element={<Productdetails />} /> */}
        <Route exact path="/digitalmenu/:name/:id" element={<DigitalMenu />} />
        <Route
          exact
          path="qr/digitalmenu/:name/:id"
          element={<DigitalMenuQr />}
        />
        <Route
          exact
          path="qr/digitalmenu/:name/:id/:showbutton"
          element={<DigitalMenuQr />}
        />
        <Route
          exact
          path="/order-form-restaurant"
          element={<OrderFormRestaurant />}
        />
        <Route
          exact
          path="/list-your-food-business"
          element={<OnBoardingLandingPage />}
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {window.location.pathname.split("/")[1] == "digitalmenu" ||
        window.location.pathname.split("/")[1] == "qr" ? (
        <></>
      ) : (
        <Footer />
      )}
    </BrowserRouter>
  );
}

export default App;
